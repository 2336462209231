<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-14 17:02:24
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:10:11
 * @Description: 货品词条选择
 * @FilePath: \src\components\commodityItemsSelect\index.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible="show"
    width="80%"
    height="100%"
    top="1vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <template>
          <!-- ------------- 检索 ------------- -->
          <div class="table-search-box">
            <!-- <div class="table-search-title">检索</div> -->
            <div class="table-search-content">
              <div class="table-search-item">
                <label>分类筛选：</label>
                <el-select size="small" v-model="commodityClassId">
                  <el-option key="All" label="全部" value="All"></el-option>
                  <el-option
                    v-for="item in itemsClassList"
                    :key="item.kcoc_id"
                    :label="item.name"
                    :value="item.kcoc_id"
                  ></el-option>
                </el-select>
              </div>

              <div class="table-search-item">
                <label>名称筛选：</label>
                <el-input
                  size="small"
                  v-model="search_key"
                  @keyup.enter.native="_LoadItems()"
                  suffix-icon="xxxx"
                ></el-input>
              </div>

              <div class="table-search-btn">
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="
                    pageNo = 1;
                    _LoadItems();
                  "
                  >查询</el-button
                >

                <el-button
                  size="small"
                  type="success"
                  icon="el-icon-plus"
                  @click="_Ok()"
                  >确认</el-button
                >
              </div>
            </div>
          </div>

          <div class="items-box">
            <label>已选货品：</label>
            <el-tag
              v-for="item in selectItems"
              :key="item.kcoi_id"
              class="item-tag"
              closable
              @click="_UpdateItem(item)"
              @close="_RemoveItem(item)"
            >
              {{ item.name }} *{{ item.amount }}
            </el-tag>
          </div>

          <el-table
            :data="TableData"
            border
            size="small"
            @row-dblclick="_SelectRow"
            style="width: 100%"
          >
            <!-- ID -->
            <el-table-column
              label="ID"
              prop="Aid"
              align="center"
              width="50px"
            ></el-table-column>

            <!-- 货品名称 -->
            <el-table-column
              label="货品名称"
              prop="kcoi_name"
              align="center"
              show-overflow-tooltip
              width="300px"
            >
            </el-table-column>

            <!-- 货品英文名 -->
            <el-table-column
              label="货品英文名"
              prop="en_name"
              align="center"
              show-overflow-tooltip
              width="300px"
            >
            </el-table-column>

            <!-- 货品敏感等级 -->
            <el-table-column label="货品敏感等级" align="center" width="150px">
              <template slot-scope="s">
                <el-tag
                  size="small"
                  effect="plain"
                  type="info"
                  v-if="s.row.sensitive_level == 190010"
                  >Ⅰ级【普货】</el-tag
                >
                <el-tag
                  size="small"
                  effect="plain"
                  v-else-if="s.row.sensitive_level == 190011"
                  >Ⅱ级【内衣裤等】</el-tag
                >
                <el-tag
                  size="small"
                  effect="plain"
                  type="warning"
                  v-else-if="s.row.sensitive_level == 190012"
                  >Ⅲ级【成人用品等】</el-tag
                >
                <el-tag
                  size="small"
                  effect="plain"
                  type="danger"
                  v-else-if="s.row.sensitive_level == 190013"
                  >Ⅳ级【证件/文件】</el-tag
                >
                <el-tag size="small" effect="plain" type="success" v-else
                  >未知</el-tag
                >
              </template>
            </el-table-column>

            <!-- 当前状态 -->
            <el-table-column label="当前状态" align="center" width="80px">
              <template slot-scope="s">
                <el-tag
                  size="small"
                  effect="plain"
                  type="success"
                  v-if="s.row.status == 1"
                  >启用</el-tag
                >
                <el-tag size="small" effect="plain" type="danger" v-else
                  >禁用</el-tag
                >
              </template>
            </el-table-column>

            <!-- 备注 -->
            <el-table-column label="备注">
              <template slot-scope="s"
                ><span>{{ s.row.notes }}</span></template
              >
            </el-table-column>

            <!-- 添加时间 -->
            <el-table-column
              label="创建时间"
              prop="add_time"
              align="center"
              width="140px"
            ></el-table-column>
          </el-table>

          <!-- ------------- 分页 ------------- -->
          <div class="page-box">
            <el-pagination
              background
              layout="total, prev, pager, next, sizes, jumper"
              :current-page.sync="pageNo"
              :page-size.sync="pageSize"
              :total="dataCount"
              :page-sizes="[15, 50, 100, 150, 200]"
              @current-change="_LoadItems()"
              @size-change="_LoadItems()"
            >
            </el-pagination>
          </div>
        </template>
      </el-card>
    </div>

    <!-- 货品属性修改器 -->
    <item-attribute ref="item-attribute" @submit="_AddItem"></item-attribute>
  </el-dialog>
</template>

<script>
import { getCommodityClassList, getCommodityItemsTable } from "./api"; //页面专有接口
import ItemAttribute from "./itemAttribute";
export default {
  components: { ItemAttribute },
  name: "declareItemsSelect",
  props: {
    value: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    default_items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: "货品选择器", //弹窗标题
      loading: true, //加载控制
      pageNo: 1, //当前页
      pageSize: 15, //分页行数
      dataCount: 0, //数据总行数
      search_key: "", //搜索词
      itemsClassList: [], //分类列表
      commodityClassId: "All", //词条分类ID
      TableData: [], //表格数据
      selectItems: [], //已选货品
    };
  },
  watch: {
    show(val) {
      if (val) {
        this._LoadItemsClass();
        this.$nextTick(() => {
          this._LoadItems();
          this.$nextTick(() => {
            this.loading = false;
          });
        });

        //写入默认值
        if (
          typeof this.default_items === "object" &&
          this.default_items.length > 0
        ) {
          let new_items = [];
          for (let i = 0; i < this.default_items.length; i++) {
            let item = this.default_items[i];
            item.price = item.price / 100;
            new_items.push(item);
          }

          this.selectItems = new_items;
        }
      }
    },
    commodityClassId(val) {
      this.TableData = [];
      if (val !== "") {
        this._LoadItems();
      }
    },
  },
  methods: {
    //加载分类数据
    _LoadItemsClass() {
      getCommodityClassList()
        .then((res) => {
          this.itemsClassList = res.data;
        })
        .catch((err) => {
          this.itemsClassList = [];
          if (err.code === 180003) {
            this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //加载货品数据
    _LoadItems() {
      getCommodityItemsTable({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        classId: this.commodityClassId,
        searchKey: this.search_key,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 180002) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //双击表格选择货品
    _SelectRow(row) {
      row = {
        kcoi_id: row.kcoi_id,
        name: row.kcoi_name,
        en_name: row.en_name,
        price: 0,
        sensitive_level: row.sensitive_level,
        amount: 1,
      };

      //获取当前ID在数组中的index 如果为空则为不存在
      let index = this.$utils.arrayGetIndex(
        this.selectItems,
        "kcoi_id",
        row.kcoi_id
      );

      //货品已存在取出已有数据
      if (index === null) {
        this.$refs["item-attribute"].open(row);
      } else {
        this._UpdateItemAmount(index);
      }
    },

    //回调-添加货品
    _AddItem(item) {
      //获取当前ID在数组中的index 如果为空则为不存在
      let index = this.$utils.arrayGetIndex(
        this.selectItems,
        "kcoi_id",
        item.kcoi_id
      );

      if (index === null) {
        //添加物品
        this.selectItems.push(item);
      } else {
        //修改物品数量
        this.selectItems[index]["amount"] = item.amount;
      }
    },

    //修改货品数量
    _UpdateItemAmount(i) {
      this.selectItems[i]["amount"]++;
    },

    //移除货品
    _RemoveItem(item) {
      this.$utils.arrayDelete(this.selectItems, item);
    },

    //修改货品
    _UpdateItem(item) {
      this.$refs["item-attribute"].open(item);
    },

    //完成选择
    _Ok() {
      this.$emit("confirm-select", this.selectItems);
      this.$nextTick(() => {
        this._HandleClose();
      });
    },

    //关闭
    _HandleClose() {
      this.$emit("update:show", false); //触发input事件，并传入新值
      Object.assign(this.$data, this.$options.data());
    },
  },
  mounted() {
    this._LoadItemsClass();
  },
};
</script>
<style>
.items-box {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0.5em 0.2em;
}
.item-tag {
  margin-right: 10px;
}
</style>
