/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-01 15:00:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 19:03:04
 * @Description: 页面专属接口管理
 * @FilePath: \src\components\commodityItemsSelect\api.js
 */

import http from "@/api";

/**************************** 词条分类操作 ****************************/

//获取分类词条列表
export const getCommodityClassList = (params) =>
  http.get("/v1/CommodityClass/GetCommodityClassList", params, false);

/**************************** 货品词条操作 ****************************/

//获取货品词条表格
export const getCommodityItemsTable = (params) =>
  http.get("/v1/Commodity/GetCommodityItemsTable", params, false);
