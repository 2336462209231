<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-16 15:31:51
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:10:19
 * @Description: 物品属性修改器
 * @FilePath: \src\components\commodityItemsSelect\itemAttribute.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :inline="true" label-width="100px">
          <el-form-item label="货品名称：" prop="name" style="width: 45%">
            <el-input
              size="small"
              v-model="item_data.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="货品英文名：" prop="en_name" style="width: 45%">
            <el-input
              size="small"
              v-model="item_data.en_name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="货品价值：" prop="price" style="width: 45%">
            <el-input size="small" v-model="item_data.price" suffix-icon="xxxx">
            </el-input>
          </el-form-item>

          <el-form-item label="数量：" prop="en_name" style="width: 45%">
            <el-input-number
              v-model="item_data.amount"
              :min="1"
              :max="1000"
            ></el-input-number>
          </el-form-item>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_Submit()"
          >提交</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      item_data: {}, //货品数据
    };
  },
  methods: {
    // 打开
    open(item) {
      this.isShow = true; //显示组件
      this.item_data = item; //重新货品数据
      this.$nextTick(() => {
        this.title = "物品属性修改器";
        this.loading = false;
      });
    },

    //提交
    _Submit() {
      console.log(this.item_data);
      this.$emit("submit", this.item_data); //回调
      this.isShow = false;
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  created() {},
};
</script>
